export const insuranceTypes = {
  kombiPlus: {
    key: "kombiPlus",
    name: "Kombi-2000-Plus",
    title: "A: Kombi-2000-Plus",
    subTitle: "Gebäude-, Hausrat-, und Glasversicherung"
  },
  kombiBasis: {
    key: "kombiBasis",
    name: "Kombi-2000-Basis",
    title:
      "B: Basisversicherung (ohne Leitungswasser, mit Entschädigungsgrenzen)",
    subTitle: "Gebäude-, Hausrat-, und Glasversicherung"
  },
  kombiKomfort: {
    key: "kombiKomfort",
    name: "Kombi-2000-Komfort",
    title: "A: Kombi-2000-Komfort",
    subTitle: "Gebäude-, Hausrat-, und Glasversicherung"
  },
  singleOutside: {
    key: "singleOutside",
    name: "Einzelversicherung ausserhalb B/BB",
    title: "C: Gebäudeversicherung zum gleitenden Neuwert",
    subTitle: "Feuer, Sturm / Hagel"
  },
  singleInside: {
    key: "singleInside",
    name: "Einzelversicherung in B/BB",
    title: "B: Gebäudeversicherung zum gleitenden Neuwert",
    subTitle: ""
  }
};

export const getByDistrict = {
  inside: [insuranceTypes.kombiPlus, insuranceTypes.singleInside],
  outside: [
    insuranceTypes.kombiKomfort,
    insuranceTypes.kombiBasis,
    insuranceTypes.singleOutside
  ]
};

export const OPTION_NAMES = {
  plus_gewaechshaus: "Gewächshaus *",
  plus_gewaechshaus_groesse: "Gewächshausfläche (in qm, max. 50)",
  plus_garage: "Garage *",
  plus_garage_anzahl: "Garagenanzahl (max. 3)",

  // NUR KOMBI
  plus_erhoehung: "Erhöhung der Hausrat-Versicherunssumme",
  plus_erhoehung_vsumme_cents: "Erhöhung Versicherungssumme (in Euro)",
  plus_glaskeramik: "Glaskeramikkochfeld",
  plus_solaranlage: "Solaranlage *",
  plus_solaranlage_vsumme_cents: "Solaranlage Versicherungssumme (in Euro)*",

  // AUSSERHALB B/BB -> Komfort, Basis, Einzel
  plus_reetdach: "Reetdach",
  plus_reetdach_groesse: "Reetdachgröße (in qm)",

  plus_aufraeumungskosten_bis_2024:
    // INNERHALB B/BB> Plus, Einzel IN
    "Aufräumkosten für durch Sturm oder Blitz umgestürzte Bäume vom Versicherungsgrundstück (bis 3000 €)",
  plus_aufraeumungskosten_ab_2025:
    // INNERHALB B/BB> Plus, Einzel IN
    "Aufräumkosten für durch Sturm oder Blitz umgestürzte Bäume vom Versicherungsgrundstück (bis 5000 €)",

  // IN B/BB -> Einzel IN
  plus_hausrat_einzelversicherung: "Hausrat Einzelversicherung",
  plus_hausrat_einzelversicherung_vsumme_cents:
    "Hausrat Einzelversicherungssumme (in Euro, min. 3000)",
  plus_glas_einzelversicherung:
    "Glasversicherung (Gebäude- und Mobiliarverglasung)",
  // plus_leitungswasser: 'Leitungswasser',
  plus_elementar:
    "Einschluss der weiteren Elementarschäden (Überschwemmung, Rückstau, Erdbeben, Erdfall, Erdrutsch, Schneedruck, Lawinen) an Gebäuden und am Hausrat.",
  plus_elementar_angrenzende_strasse1:
    "Weitere an die KGA angrenzende Straße 1",
  plus_elementar_angrenzende_strasse2:
    "Weitere an die KGA angrenzende Straße 2",
  plus_elementar_angrenzende_strasse3:
    "Weitere an die KGA angrenzende Straße 3",
  plus_elementar_angrenzende_strasse4:
    "Weitere an die KGA angrenzende Straße 4",
  plus_elementar_anzahl_vorschaeden: "Anzahl Vorschäden",
  plus_elementar_hausnummer: "Hausnummer",
  plus_elementar_keller: "Keller",
  plus_elementar_mit_vorschaden: "Vorschaden vorhanden (max. 1 Vorschaden)",
  plus_elementar_ort: "Ort",
  plus_elementar_plz: "PLZ",
  plus_elementar_rueckstausicherung: "Rückstausicherung vorhanden",
  plus_elementar_strasse: "Straße",
  plus_leitungswasser: "Feuer, Sturm / Hagel und Leitungswasser"
};

export const getOptionsByInsurance = insurance => {
  const generalTypes = [
    "plus_gewaechshaus",
    "plus_gewaechshaus_groesse",
    "plus_garage",
    "plus_garage_anzahl"
  ];
  let dynamicTypes = [];
  // CHECK KOMBI
  if (
    [
      insuranceTypes.kombiPlus.name,
      insuranceTypes.kombiBasis.name,
      insuranceTypes.kombiKomfort.name
    ].indexOf(insurance) !== -1
  ) {
    dynamicTypes = [
      ...dynamicTypes,
      "plus_erhoehung",
      "plus_erhoehung_vsumme_cents",
      "plus_glaskeramik",
      "plus_solaranlage",
      "plus_solaranlage_vsumme_cents"
    ];
  }

  // KOMBI 2000 PLUS
  if ([insuranceTypes.kombiPlus.name].indexOf(insurance) !== -1) {
    dynamicTypes = [...dynamicTypes, "plus_reetdach", "plus_reetdach_groesse"];
  }

  // AUSSERHALB BBB
  if (
    [
      insuranceTypes.kombiBasis.name,
      insuranceTypes.kombiKomfort.name,
      insuranceTypes.singleOutside.name
    ].indexOf(insurance) !== -1
  ) {
    dynamicTypes = [...dynamicTypes, "plus_reetdach", "plus_reetdach_groesse"];
  }

  // IN BBB
  if (
    [
      insuranceTypes.kombiPlus.name,
      insuranceTypes.singleInside.name,
      insuranceTypes.kombiKomfort.name
    ].indexOf(insurance) !== -1
  ) {
    dynamicTypes = [...dynamicTypes, "plus_aufraeumungskosten"];
  }

  // IN BBB Einzel IN
  if ([insuranceTypes.singleInside.name].indexOf(insurance) !== -1) {
    dynamicTypes = [
      ...dynamicTypes,
      "plus_reetdach",
      "plus_reetdach_groesse",
      // "plus_hausrat_einzelversicherung",
      // "plus_hausrat_einzelversicherung_vsumme_cents",
      "plus_glas_einzelversicherung"
      // 'plus_leitungswasser',
    ];
  }

  return [...generalTypes, ...dynamicTypes];
};

export function buildVariants(form, fields) {
  var newFields = { ...fields };

  // LEITUNGSWASSER
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_leitungswasser) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name)
  ) {
    delete newFields.beratung_leitungswasser;
    delete newFields.beratung_laube;
  }

  // GEWAECHSHAUS
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name &&
      form.optionen.plus_gewaechshaus) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_gewaechshaus) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name &&
      form.optionen.plus_gewaechshaus) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name &&
      form.optionen.plus_gewaechshaus) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleOutside.name &&
      form.optionen.plus_gewaechshaus)
  ) {
    delete newFields.beratung_feuer_sturm_gewaechshaus;
  }

  // GARAGEN
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name &&
      form.optionen.plus_garage) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_garage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name &&
      form.optionen.plus_garage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name &&
      form.optionen.plus_garage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleOutside.name &&
      form.optionen.plus_garage)
  ) {
    delete newFields.beratung_feuer_sturm_garage;
  }

  // SOLARANLAGEN
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name &&
      form.optionen.plus_solaranlage) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_solaranlage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name &&
      form.optionen.plus_solaranlage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name &&
      form.optionen.plus_solaranlage) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleOutside.name &&
      form.optionen.plus_solaranlage)
  ) {
    delete newFields.beratung_diebstahl_vandalismus_solar;
  }

  // AUFRAEUMUNGSKOSTEN
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name &&
      form.optionen.plus_aufraeumungskosten) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_aufraeumungskosten) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name &&
      form.optionen.plus_aufraeumungskosten) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name &&
      form.optionen.plus_aufraeumungskosten) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleOutside.name &&
      form.optionen.plus_aufraeumungskosten)
  ) {
    delete newFields.beratung_aufraeumkosten_sturm_blitz;
  }

  // GLASKERAMIKKOCHFELD
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name &&
      form.optionen.plus_glaskeramik) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name &&
      form.optionen.plus_glaskeramik) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name &&
      form.optionen.plus_glaskeramik) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleOutside.name &&
      form.optionen.plus_glaskeramik)
  ) {
    delete newFields.beratung_glasbruchschaeden_glaskeramik;
  }

  // GLASBRUCHSCHAEDEN
  if (
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiPlus.name) ||
    (form.district.in_bbb &&
      form.versicherung === insuranceTypes.singleInside.name &&
      form.optionen.plus_glas_einzelversicherung) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiKomfort.name) ||
    (!form.district.in_bbb &&
      form.versicherung === insuranceTypes.kombiBasis.name)
  ) {
    delete newFields.beratung_glasbruchschaeden;
    delete newFields.beratung_absicherung_laubeninhalt_sowie_glasbruchschaeden;
  }

  // ELEMENTAR
  if (
    form.district.in_bbb &&
    form.versicherung === insuranceTypes.kombiPlus.name &&
    form.optionen.plus_elementar
  ) {
    delete newFields.beratung_weitere_elementargefahren;
  }

  // LAUBENINHALT
  if (
    (form.district.in_bbb &&
      form.versicherung !== insuranceTypes.singleInside.name) ||
    (!form.district.in_bbb &&
      form.versicherung !== insuranceTypes.singleOutside.name)
  ) {
    delete newFields.beratung_absicherung_laubeninhalt;
  }

  return newFields;
}

export default insuranceTypes;
